
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonIcon, IonButton, modalController, menuController, IonButtons } from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, computed } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import moment from "moment";
import { Calendar } from "v-calendar";

import { openToast } from "@/services/toast";
import { dateFormat, timeFormat } from "@/services/utils";

import apiCalendario from "@/services/calendario";

import EventDetail from "@/components/calendario/EventDetail.vue";

export default {
    name: "Calendario",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
        Calendar,
    },
    setup() {
        const loading = ref(false);
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;

        const openMenu = () => {
            menuController.open("app-menu");
        };

        /**
         * ! Get all appuntamenti
         */
        const events = ref([]);
        const eventsToShow = ref([]);
        async function loadEvents() {
            loading.value = true;
            try {
                const res = await apiCalendario.getEvents(userID);
                if (res.status === 200 && res.data.status === 0) {
                    if (res.data.data.length != 0) {
                        // Ordino in base a data inizio
                        res.data.data.sort((a, b) => {
                            const dateA = new Date(a.events_date_start).getTime();
                            const dateB = new Date(b.events_date_start).getTime();
                            return dateA - dateB;
                        });
                        events.value = res.data.data;
                    } else {
                        events.value = res.data.data;
                    }
                    //Filtro appuntamenti di oggi per mostrarli subito
                    eventsToShow.value = events.value.filter((event) => moment(event.events_date_start).isSame(moment(), "day"));
                } else {
                    openToast("Errore durante la richiesta degli eventi", "toast_danger");
                }
            } catch (error) {
                events.value = [];
                openToast("Errore durante la richiesta degli eventi", "toast_danger");
            } finally {
                loading.value = false;
            }
        }
        loadEvents();

        /**
         * ! Set calendar data
         */
        const date = ref(new Date());
        const selectedDay = ref(null); // Add state to store selected day

        const setAttributes = computed(() => {
            return events.value.map((event) => ({
                key: `event.${event.events_id}`,
                highlight: {
                    color: "blue",
                    fillMode: "solid",
                },
                dates: event.events_date_start,
                customData: event,
            }));
        });

        /**
         * ! Mostra solo gli appuntamenti del giorno selezioanto
         */
        const filterEvents = (day) => {
            selectedDay.value = day;
            eventsToShow.value = [];
            //eventsToShow.value = [...day.attributes];
            eventsToShow.value = day.attributes.length == 0 ? [] : day.attributes.map((appuntamento) => appuntamento.customData);
        };

        /**
         * ! Open event detail page
         */
        async function openDetailModal(event) {
            let currentEventId = event.events_id;

            const modal = await modalController.create({
                component: EventDetail,
                componentProps: {
                    data: event,
                },
            });

            modal.onDidDismiss().then((detail) => {
                //console.log(detail);
                if (detail.data) {
                    const deleted = detail.data;

                    if (deleted && currentEventId) {
                        //Filtro array event togliendo quello eliminato
                        events.value = events.value.filter((event) => event.events_id != currentEventId);
                        //Filtro array eventi visualizzati togliendo quello eliminato
                        eventsToShow.value = eventsToShow.value.filter((event) => event.events_id != currentEventId);

                        openToast("Evento eliminato con successo", "toast_success");
                    } else {
                        openToast("Si è verificato un errore durante l'eliminazione dell'evento", "toast_danger");
                    }
                }
                // Reset current event
                currentEventId = null;
            });

            return modal.present();
        }

        return {
            loading,
            add,
            refresh,
            openDetailModal,
            menu,
            openMenu,
            //Appuntamenti
            loadEvents,
            dateFormat,
            timeFormat,
            events,
            Calendar,
            date,
            selectedDay,
            filterEvents,
            setAttributes,
            eventsToShow,
        };
    },
};
