<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio evento</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="event">
                    <!-- <div class="title">{{ dateFormat(event.events_date_start) }}</div>
                    <div class="hours">
                        {{ timeFormat(event.events_date_start) }} - {{ timeFormat(event.events_date_end) }}
                    </div> -->
                    <div v-if="event.events_title" class="event_title">
                        <div class="event_name">{{ event.events_title }}</div>
                    </div>
                    <div class="title">
                        <!-- <div class="event_date">{{ dateFormat(event.events_date_start) }} - {{ dateFormat(event.events_date_end) }}</div> -->
                        <div class="event_date">{{ formatEventDate(event.events_date_start, event.events_date_end) }}</div>
                        <div class="event_time">
                            <div>{{ timeFormat(event.events_date_start) }} - {{ timeFormat(event.events_date_end) }}</div>
                        </div>
                    </div>
                    <!--                     <div class="field">
                        <div class="title">Data</div>
                        <div class="value">
                            {{ dateFormat(event.events_date_start) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Inizio</div>
                        <div class="value">{{ timeFormat(event.events_date_start) }}</div>
                    </div>
                    <div class="field">
                        <div class="title">Fine</div>
                        <div class="value">{{ timeFormat(event.events_date_end) }}</div>
                    </div> -->
                    <div v-if="event.events_google_calendar" class="field_note">
                        <div class="title">Google calendar</div>
                        <div class="value">
                            {{ event.google_calendars_list_google_id }}
                        </div>
                    </div>
                    <div v-if="event.events_description" class="field_note">
                        <div class="title"></div>
                        <div class="value">
                            {{ event.events_description }}
                        </div>
                    </div>
                    <!-- Dati cliente collegato -->
                    <div v-if="event.events_customer_id" class="customer_data">
                        <div class="title">
                            {{ event.customers_full_name }}
                        </div>
                        <div class="field">
                            <div class="title">Telefono</div>
                            <div v-if="event.customers_mobile" class="value">
                                <a :href="`tel:${event.customers_mobile}`" target="_blank" class="mobile_link">{{ event.customers_mobile }}</a>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="field">
                            <div class="title">Email</div>
                            <div v-if="event.customers_email" class="value">
                                <a :href="`mailto:${event.customers_email}`" target="_blank" class="mobile_link">{{ event.customers_email }}</a>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="field_note">
                            <div class="title">Indirizzo</div>
                            <div class="value">
                                <a
                                    :href="`https://maps.google.com/?q=${event.customers_city} ${event.customers_address} ${event.customers_zip_code}`"
                                    target="_blank"
                                    class="mobile_link mobile_link_maps"
                                >
                                    {{ formattedAddress() }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="delete_button_container">
                    <button @click="deleteEvent()" class="btn_delete_event">Elimina evento</button>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    modalController,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";
import { alertController } from "@ionic/vue";

import { arrowBackOutline } from "ionicons/icons";

import moment from "moment";
import { openToast } from "@/services/toast";
import { dateFormat, timeFormat } from "@/services/utils";

import apiCalendario from "@/services/calendario";

export default defineComponent({
    name: "EvetDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
    },
    setup(props, context) {
        //console.log(props.data);
        const event = ref([]);
        event.value = { ...props.data };
        //console.log(event.value);

        /**
         * Handle close modal by clicking on Chiudi button
         */
        const closeModal = (deleted = false) => {
            modalController.dismiss(deleted);
        };

        const formatEventDate = (startDate, endDate) => {
            const start = new Date(startDate);
            const end = new Date(endDate);

            if (start.getFullYear() === end.getFullYear() && start.getMonth() === end.getMonth() && start.getDate() === end.getDate()) {
                return dateFormat(startDate);
            } else {
                return `${dateFormat(startDate)} - ${dateFormat(endDate)}`;
            }
        };

        const formattedAddress = () => {
            const { customers_address, customers_city, customers_province } = event.value;

            let formatted = "";

            if (customers_address && customers_address.trim() !== "") {
                formatted += customers_address + ", ";
            }
            if (customers_city && customers_city.trim() !== "") {
                formatted += customers_city + " (";
            }
            if (customers_province && customers_province.trim() !== "") {
                formatted += customers_province + ")";
            }

            return formatted;
        };

        async function handleCancellation() {
            if (event.value.events_id) {
                let deleted = false;

                const res = await apiCalendario.deleteEvent(event.value.events_id);

                if (res.status === 200 && res.data.status === 0) {
                    deleted = true;
                    closeModal(deleted);
                } else {
                    openToast("Si è verificato un durante l'eliminazione dell'evento", "toast_danger");
                    closeModal(deleted);
                }
            } else {
                openToast("Non è stato possibile riconscere l'evento da eliminare", "toast_danger");
            }
        }

        async function showAlert() {
            const alert = await alertController.create({
                header: "Elimina evento",
                message: "Vuoi eliminare definitivamente questo evento?",
                backdropDismiss: false,
                buttons: [
                    {
                        text: "Annulla",
                        handler: () => {
                            // Close alert
                        },
                    },
                    {
                        text: "Elimina",
                        handler: () => {
                            handleCancellation();
                        },
                    },
                ],
            });
            return alert.present();
        }

        async function deleteEvent() {
            await showAlert();
        }

        return {
            event,
            dateFormat,
            timeFormat,
            arrowBackOutline,
            closeModal,
            deleteEvent,
            formatEventDate,
            formattedAddress,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

.description_title {
    color: var(--ion-color-primary, #3880ff) !important;
    margin: 10px 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new event btn */
    padding: 16px;
    min-height: 100%;
    background: #f2f2f2;
}

.event {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.event_customer {
    margin-top: 24px;
}
.event > .event_title,
.event .customer_data > .title {
    width: 100%;
    padding: 12px 6px;
    color: #121212;
    text-align: center;
}
.event > .title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
}
.event .event_title .event_name {
    font-size: 18px;
    font-weight: 500;
}
.event .title .event_date {
    font-size: 16px;
    font-weight: 500;
}
.event .title .event_time {
    display: flex;
    justify-content: center;
    gap: 4px;
    font-size: 16px;
    font-weight: 500;
}

.event .customer_data {
    width: 100%;
    margin-top: 24px;
    border-top: 0.5px solid #7676803d;
}
.event .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 12px 6px;*/
    padding: 12px 0px;
    /*margin-bottom: 10px;*/
    border-bottom: 1px solid #e2e8f0;
}
.event .customer_data .field {
    border-bottom: none;
}
.event .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px 0px;
    border-bottom: none;
}
.event .field_ricevuta {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px 6px;
}
.field .title,
.field_note .title,
.field_ricevuta .title {
    font-size: 14px;
    font-weight: 500;
    color: #121212;
}
.field_ricevuta .title {
    margin-bottom: 8px;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value,
.field_ricevuta .value {
    font-size: 14px;
}

.value .badge {
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}

.value .badge.badge_inserita {
    background-color: rgb(254 243 199);
    color: rgb(245 158 11);
}
.value .badge.badge_rimborsata {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
}
.value .badge.badge_rifiutata {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
}
.value .badge.badge_accettata {
    background-color: rgb(219 234 254);
    color: rgb(59 130 246);
}
ion-button {
    --color: #ffffff;
}

.delete_button_container {
    margin-top: 24px;
}

.btn_delete_event {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: rgb(220 38 38);
    background-color: rgb(254 202 202);
    font-size: 16px;
    font-weight: bold;
    border-radius: 20px;
}

.mobile_link {
    color: #086fa3;
    text-decoration: none;
}
.mobile_link_maps {
    width: 100%;
    display: block;
    text-align: right;
}
</style>
